import '@usb-shield/react-grid/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { HeroViewData } from '@/components/HeroBanner/heroBannerData.model'
import heroStyles from '@/components/HeroBanner/heroBanner.module.scss'

const leftCol = {
  span: null,
  spans: {
    xlarge: 6,
    large: 8,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'auto',
  justify: 'auto',
}

const rightCol = {
  span: null,
  spans: {
    xlarge: 6,
    large: 8,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'auto',
  justify: 'auto',
}

const TwoColBannerImageRight = ({
  title,
  mainHead,
  shortDescription,
  image,
  cta1Text,
  cta1Link,
  cta2Text,
  cta2Link,
}: HeroViewData) => {
  let imageSrc = process.env.CONTENT_DOMAIN + image
  if (!shortDescription) {
    shortDescription = ''
  }

  return (
    <section
      id="TwoColBannerImageRight"
      className={heroStyles.twoColBannerSection}
    >
      <div
        className={heroStyles.twoColBannerImageRightContainerBackground}
      ></div>
      <USBGrid
        backgroundColor="heroBg"
        gridGap="normal"
        alignItems="middle"
        columnCount="16"
        justifyContent="stretch"
        display="grid"
        padding="normal"
        addClasses={heroStyles.twoColBannerImageRightContainer}
      >
        <USBColumn
          layoutOpts={leftCol}
          addClasses={heroStyles.twoColBannerRightLeftColumn}
        >
          <div>
            <h1 className={heroStyles.twoColBannerH1}>{title}</h1>
            <h2 className={heroStyles.twoColBannerSubHeader}>{mainHead}</h2>
            {shortDescription ? (
              <p dangerouslySetInnerHTML={{ __html: shortDescription }}></p>
            ) : (
              ''
            )}
          </div>
        </USBColumn>
        <USBColumn layoutOpts={rightCol}>
          <div className={heroStyles.imageWrapper}>
            <img src={imageSrc} alt="" />
          </div>
        </USBColumn>
      </USBGrid>
    </section>
  )
}

export default TwoColBannerImageRight
