import FullPageBanner from '@/components/HeroBanner/DisplayViews/FullPageBanner'
import { HeroData } from '@/components/HeroBanner/heroBannerData.model'
import TwoColBannerImageRight from '@/components/HeroBanner/DisplayViews/TwoColBannerImageRight'
import ExploreAllApiBanner from '@/components/HeroBanner/DisplayViews/ExploreAllApiBanner'

const HeroBanner = ({
  title,
  mainHead,
  shortDescription,
  image,
  cta1Text,
  cta1Link,
  cta2Text,
  cta2Link,
  displayView,
}: HeroData) => {
  let pageContent: any[] = []
  let ele: any = ''

  switch (displayView) {
    case 'FullPageBanner':
      ele = (
        <FullPageBanner
          key="FullPageBanner"
          title={title}
          mainHead={mainHead}
          shortDescription={shortDescription}
          image={image}
          cta1Link={cta1Link}
          cta1Text={cta1Text}
          cta2Link={cta2Link}
          cta2Text={cta2Text}
        />
      )

      pageContent.push(ele)
      break

    case 'TwoColBannerImageRight':
      ele = (
        <TwoColBannerImageRight
          key="TwoColBannerImageRight"
          title={title}
          mainHead={mainHead}
          image={image}
          shortDescription={shortDescription}
          cta1Link={cta1Link}
          cta1Text={cta1Text}
          cta2Link={cta2Link}
          cta2Text={cta2Text}
        />
      )

      pageContent.push(ele)
      break

      case 'ExploreAllApiBanner':
      ele = (
        <ExploreAllApiBanner
          key="ExploreAllApiBanner"
          title={title}
          mainHead={mainHead}
          image={image}
          shortDescription={shortDescription}
          cta1Link={cta1Link}
          cta1Text={cta1Text}
          cta2Link={cta2Link}
          cta2Text={cta2Text}
        />
      )

      pageContent.push(ele)
      break
  }

  return <>{pageContent}</>
}

export default HeroBanner
